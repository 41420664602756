<template>
    <woot-button
      variant="link"
      class="items-center flex rounded-full"
      @click="handleClick"
    >
      <thumbnail
        :src="currentUser.avatar_url"
        :username="currentUser.name"
        :status="statusOfAgent"
        :title="$t(`SIDEBAR.PROFILE_SETTINGS`)"
        should-show-status-always
        size="46px"
      />
    </woot-button>
</template>
<script>
import { mapGetters } from 'vuex';
import Thumbnail from '../../widgets/Thumbnail.vue';

export default {
  components: {
    Thumbnail,
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
      currentUserAvailability: 'getCurrentUserAvailability',
    }),
    statusOfAgent() {
      return this.currentUserAvailability || 'offline';
    },
  },
  methods: {
    handleClick() {
      this.$emit('toggle-menu');
    },
  },
};
</script>
