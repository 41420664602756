import { render, staticRenderFns } from "./Sms.vue?vue&type=template&id=72804740&"
import script from "./Sms.vue?vue&type=script&lang=js&"
export * from "./Sms.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/Automate-it/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('72804740')) {
      api.createRecord('72804740', component.options)
    } else {
      api.reload('72804740', component.options)
    }
    module.hot.accept("./Sms.vue?vue&type=template&id=72804740&", function () {
      api.rerender('72804740', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "app/javascript/dashboard/routes/dashboard/settings/inbox/channels/Sms.vue"
export default component.exports