import { render, staticRenderFns } from "./Banner.vue?vue&type=template&id=6c53415f&scoped=true&"
import script from "./Banner.vue?vue&type=script&lang=js&"
export * from "./Banner.vue?vue&type=script&lang=js&"
import style0 from "./Banner.vue?vue&type=style&index=0&id=6c53415f&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c53415f",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/Automate-it/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6c53415f')) {
      api.createRecord('6c53415f', component.options)
    } else {
      api.reload('6c53415f', component.options)
    }
    module.hot.accept("./Banner.vue?vue&type=template&id=6c53415f&scoped=true&", function () {
      api.rerender('6c53415f', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "app/javascript/dashboard/components/ui/Banner.vue"
export default component.exports