// const countries =
// [
//   {
//     name: 'Afghanistan',
//     dial_code: '+93',
//     emoji: '🇦🇫',
//     id: 'AF',
//   },
//   {
//     name: 'Aland Islands',
//     dial_code: '+358',
//     emoji: '🇦🇽',
//     id: 'AX',
//   },
//   {
//     name: 'Albania',
//     dial_code: '+355',
//     emoji: '🇦🇱',
//     id: 'AL',
//   },
//   {
//     name: 'Algeria',
//     dial_code: '+213',
//     emoji: '🇩🇿',
//     id: 'DZ',
//   },
//   {
//     name: 'AmericanSamoa',
//     dial_code: '+1684',
//     emoji: '🇦🇸',
//     id: 'AS',
//   },
//   {
//     name: 'Andorra',
//     dial_code: '+376',
//     emoji: '🇦🇩',
//     id: 'AD',
//   },
//   {
//     name: 'Angola',
//     dial_code: '+244',
//     emoji: '🇦🇴',
//     id: 'AO',
//   },
//   {
//     name: 'Anguilla',
//     dial_code: '+1264',
//     emoji: '🇦🇮',
//     id: 'AI',
//   },
//   {
//     name: 'Antarctica',
//     dial_code: '+672',
//     emoji: '🇦🇶',
//     id: 'AQ',
//   },
//   {
//     name: 'Antigua and Barbuda',
//     dial_code: '+1268',
//     emoji: '🇦🇬',
//     id: 'AG',
//   },
//   {
//     name: 'Argentina',
//     dial_code: '+54',
//     emoji: '🇦🇷',
//     id: 'AR',
//   },
//   {
//     name: 'Armenia',
//     dial_code: '+374',
//     emoji: '🇦🇲',
//     id: 'AM',
//   },
//   {
//     name: 'Aruba',
//     dial_code: '+297',
//     emoji: '🇦🇼',
//     id: 'AW',
//   },
//   {
//     name: 'Australia',
//     dial_code: '+61',
//     emoji: '🇦🇺',
//     id: 'AU',
//   },
//   {
//     name: 'Austria',
//     dial_code: '+43',
//     emoji: '🇦🇹',
//     id: 'AT',
//   },
//   {
//     name: 'Azerbaijan',
//     dial_code: '+994',
//     emoji: '🇦🇿',
//     id: 'AZ',
//   },
//   {
//     name: 'Bahamas',
//     dial_code: '+1242',
//     emoji: '🇧🇸',
//     id: 'BS',
//   },
//   {
//     name: 'Bahrain',
//     dial_code: '+973',
//     emoji: '🇧🇭',
//     id: 'BH',
//   },
//   {
//     name: 'Bangladesh',
//     dial_code: '+880',
//     emoji: '🇧🇩',
//     id: 'BD',
//   },
//   {
//     name: 'Barbados',
//     dial_code: '+1246',
//     emoji: '🇧🇧',
//     id: 'BB',
//   },
//   {
//     name: 'Belarus',
//     dial_code: '+375',
//     emoji: '🇧🇾',
//     id: 'BY',
//   },
//   {
//     name: 'Belgium',
//     dial_code: '+32',
//     emoji: '🇧🇪',
//     id: 'BE',
//   },
//   {
//     name: 'Belize',
//     dial_code: '+501',
//     emoji: '🇧🇿',
//     id: 'BZ',
//   },
//   {
//     name: 'Benin',
//     dial_code: '+229',
//     emoji: '🇧🇯',
//     id: 'BJ',
//   },
//   {
//     name: 'Bermuda',
//     dial_code: '+1441',
//     emoji: '🇧🇲',
//     id: 'BM',
//   },
//   {
//     name: 'Bhutan',
//     dial_code: '+975',
//     emoji: '🇧🇹',
//     id: 'BT',
//   },
//   {
//     name: 'Bolivia, Plurinational State of',
//     dial_code: '+591',
//     emoji: '🇧🇴',
//     id: 'BO',
//   },
//   {
//     name: 'Bosnia and Herzegovina',
//     dial_code: '+387',
//     emoji: '🇧🇦',
//     id: 'BA',
//   },
//   {
//     name: 'Botswana',
//     dial_code: '+267',
//     emoji: '🇧🇼',
//     id: 'BW',
//   },
//   {
//     name: 'Brazil',
//     dial_code: '+55',
//     emoji: '🇧🇷',
//     id: 'BR',
//   },
//   {
//     name: 'British Indian Ocean Territory',
//     dial_code: '+246',
//     emoji: '🇮🇴',
//     id: 'IO',
//   },
//   {
//     name: 'Brunei Darussalam',
//     dial_code: '+673',
//     emoji: '🇧🇳',
//     id: 'BN',
//   },
//   {
//     name: 'Bulgaria',
//     dial_code: '+359',
//     emoji: '🇧🇬',
//     id: 'BG',
//   },
//   {
//     name: 'Burkina Faso',
//     dial_code: '+226',
//     emoji: '🇧🇫',
//     id: 'BF',
//   },
//   {
//     name: 'Burundi',
//     dial_code: '+257',
//     emoji: '🇧🇮',
//     id: 'BI',
//   },
//   {
//     name: 'Cambodia',
//     dial_code: '+855',
//     emoji: '🇰🇭',
//     id: 'KH',
//   },
//   {
//     name: 'Cameroon',
//     dial_code: '+237',
//     emoji: '🇨🇲',
//     id: 'CM',
//   },
//   {
//     name: 'Canada',
//     dial_code: '+1',
//     emoji: '🇨🇦',
//     id: 'CA',
//   },
//   {
//     name: 'Cape Verde',
//     dial_code: '+238',
//     emoji: '🇨🇻',
//     id: 'CV',
//   },
//   {
//     name: 'Cayman Islands',
//     dial_code: '+345',
//     emoji: '🇰🇾',
//     id: 'KY',
//   },
//   {
//     name: 'Central African Republic',
//     dial_code: '+236',
//     emoji: '🇨🇫',
//     id: 'CF',
//   },
//   {
//     name: 'Chad',
//     dial_code: '+235',
//     emoji: '🇹🇩',
//     id: 'TD',
//   },
//   {
//     name: 'Chile',
//     dial_code: '+56',
//     emoji: '🇨🇱',
//     id: 'CL',
//   },
//   {
//     name: 'China',
//     dial_code: '+86',
//     emoji: '🇨🇳',
//     id: 'CN',
//   },
//   {
//     name: 'Christmas Island',
//     dial_code: '+61',
//     emoji: '🇨🇽',
//     id: 'CX',
//   },
//   {
//     name: 'Cocos (Keeling) Islands',
//     dial_code: '+61',
//     emoji: '🇨🇨',
//     id: 'CC',
//   },
//   {
//     name: 'Colombia',
//     dial_code: '+57',
//     emoji: '🇨🇴',
//     id: 'CO',
//   },
//   {
//     name: 'Comoros',
//     dial_code: '+269',
//     emoji: '🇰🇲',
//     id: 'KM',
//   },
//   {
//     name: 'Congo',
//     dial_code: '+242',
//     emoji: '🇨🇬',
//     id: 'CG',
//   },
//   {
//     name: 'Congo, The Democratic Republic of the Congo',
//     dial_code: '+243',
//     emoji: '🇨🇩',
//     id: 'CD',
//   },
//   {
//     name: 'Cook Islands',
//     dial_code: '+682',
//     emoji: '🇨🇰',
//     id: 'CK',
//   },
//   {
//     name: 'Costa Rica',
//     dial_code: '+506',
//     emoji: '🇨🇷',
//     id: 'CR',
//   },
//   {
//     name: "Cote d'Ivoire",
//     dial_code: '+225',
//     emoji: '🇨🇮',
//     id: 'CI',
//   },
//   {
//     name: 'Croatia',
//     dial_code: '+385',
//     emoji: '🇭🇷',
//     id: 'HR',
//   },
//   {
//     name: 'Cuba',
//     dial_code: '+53',
//     emoji: '🇨🇺',
//     id: 'CU',
//   },
//   {
//     name: 'Cyprus',
//     dial_code: '+357',
//     emoji: '🇨🇾',
//     id: 'CY',
//   },
//   {
//     name: 'Czech Republic',
//     dial_code: '+420',
//     emoji: '🇨🇿',
//     id: 'CZ',
//   },
//   {
//     name: 'Denmark',
//     dial_code: '+45',
//     emoji: '🇩🇰',
//     id: 'DK',
//   },
//   {
//     name: 'Djibouti',
//     dial_code: '+253',
//     emoji: '🇩🇯',
//     id: 'DJ',
//   },
//   {
//     name: 'Dominica',
//     dial_code: '+1767',
//     emoji: '🇩🇲',
//     id: 'DM',
//   },
//   {
//     name: 'Dominican Republic',
//     dial_code: '+1849',
//     emoji: '🇩🇴',
//     id: 'DO',
//   },
//   {
//     name: 'Ecuador',
//     dial_code: '+593',
//     emoji: '🇪🇨',
//     id: 'EC',
//   },
//   {
//     name: 'Egypt',
//     dial_code: '+20',
//     emoji: '🇪🇬',
//     id: 'EG',
//     regex:'/^\+?[0-9]\d{1,20}$/'
//   },
//   {
//     name: 'El Salvador',
//     dial_code: '+503',
//     emoji: '🇸🇻',
//     id: 'SV',
//   },
//   {
//     name: 'Equatorial Guinea',
//     dial_code: '+240',
//     emoji: '🇬🇶',
//     id: 'GQ',
//   },
//   {
//     name: 'Eritrea',
//     dial_code: '+291',
//     emoji: '🇪🇷',
//     id: 'ER',
//   },
//   {
//     name: 'Estonia',
//     dial_code: '+372',
//     emoji: '🇪🇪',
//     id: 'EE',
//   },
//   {
//     name: 'Ethiopia',
//     dial_code: '+251',
//     emoji: '🇪🇹',
//     id: 'ET',
//   },
//   {
//     name: 'Falkland Islands (Malvinas)',
//     dial_code: '+500',
//     emoji: '🇫🇰',
//     id: 'FK',
//   },
//   {
//     name: 'Faroe Islands',
//     dial_code: '+298',
//     emoji: '🇫🇴',
//     id: 'FO',
//   },
//   {
//     name: 'Fiji',
//     dial_code: '+679',
//     emoji: '🇫🇯',
//     id: 'FJ',
//   },
//   {
//     name: 'Finland',
//     dial_code: '+358',
//     emoji: '🇫🇮',
//     id: 'FI',
//   },
//   {
//     name: 'France',
//     dial_code: '+33',
//     emoji: '🇫🇷',
//     id: 'FR',
//   },
//   {
//     name: 'French Guiana',
//     dial_code: '+594',
//     emoji: '🇬🇫',
//     id: 'GF',
//   },
//   {
//     name: 'French Polynesia',
//     dial_code: '+689',
//     emoji: '🇵🇫',
//     id: 'PF',
//   },
//   {
//     name: 'Gabon',
//     dial_code: '+241',
//     emoji: '🇬🇦',
//     id: 'GA',
//   },
//   {
//     name: 'Gambia',
//     dial_code: '+220',
//     emoji: '🇬🇲',
//     id: 'GM',
//   },
//   {
//     name: 'Georgia',
//     dial_code: '+995',
//     emoji: '🇬🇪',
//     id: 'GE',
//   },
//   {
//     name: 'Germany',
//     dial_code: '+49',
//     emoji: '🇩🇪',
//     id: 'DE',
//   },
//   {
//     name: 'Ghana',
//     dial_code: '+233',
//     emoji: '🇬🇭',
//     id: 'GH',
//   },
//   {
//     name: 'Gibraltar',
//     dial_code: '+350',
//     emoji: '🇬🇮',
//     id: 'GI',
//   },
//   {
//     name: 'Greece',
//     dial_code: '+30',
//     emoji: '🇬🇷',
//     id: 'GR',
//   },
//   {
//     name: 'Greenland',
//     dial_code: '+299',
//     emoji: '🇬🇱',
//     id: 'GL',
//   },
//   {
//     name: 'Grenada',
//     dial_code: '+1473',
//     emoji: '🇬🇩',
//     id: 'GD',
//   },
//   {
//     name: 'Guadeloupe',
//     dial_code: '+590',
//     emoji: '🇬🇵',
//     id: 'GP',
//   },
//   {
//     name: 'Guam',
//     dial_code: '+1671',
//     emoji: '🇬🇺',
//     id: 'GU',
//   },
//   {
//     name: 'Guatemala',
//     dial_code: '+502',
//     emoji: '🇬🇹',
//     id: 'GT',
//   },
//   {
//     name: 'Guernsey',
//     dial_code: '+44',
//     emoji: '🇬🇬',
//     id: 'GG',
//   },
//   {
//     name: 'Guinea',
//     dial_code: '+224',
//     emoji: '🇬🇳',
//     id: 'GN',
//   },
//   {
//     name: 'Guinea-Bissau',
//     dial_code: '+245',
//     emoji: '🇬🇼',
//     id: 'GW',
//   },
//   {
//     name: 'Guyana',
//     dial_code: '+595',
//     emoji: '🇬🇾',
//     id: 'GY',
//   },
//   {
//     name: 'Haiti',
//     dial_code: '+509',
//     emoji: '🇭🇹',
//     id: 'HT',
//   },
//   {
//     name: 'Holy See (Vatican City State)',
//     dial_code: '+379',
//     emoji: '🇻🇦',
//     id: 'VA',
//   },
//   {
//     name: 'Honduras',
//     dial_code: '+504',
//     emoji: '🇭🇳',
//     id: 'HN',
//   },
//   {
//     name: 'Hong Kong',
//     dial_code: '+852',
//     emoji: '🇭🇰',
//     id: 'HK',
//   },
//   {
//     name: 'Hungary',
//     dial_code: '+36',
//     emoji: '🇭🇺',
//     id: 'HU',
//   },
//   {
//     name: 'Iceland',
//     dial_code: '+354',
//     emoji: '🇮🇸',
//     id: 'IS',
//   },
//   {
//     name: 'India',
//     dial_code: '+91',
//     emoji: '🇮🇳',
//     id: 'IN',
//   },
//   {
//     name: 'Indonesia',
//     dial_code: '+62',
//     emoji: '🇮🇩',
//     id: 'ID',
//   },
//   {
//     name: 'Iran, Islamic Republic of Persian Gulf',
//     dial_code: '+98',
//     emoji: '🇮🇷',
//     id: 'IR',
//   },
//   {
//     name: 'Iraq',
//     dial_code: '+964',
//     emoji: '🇮🇷',
//     id: 'IQ',
//   },
//   {
//     name: 'Ireland',
//     dial_code: '+353',
//     emoji: '🇮🇪',
//     id: 'IE',
//   },
//   {
//     name: 'Isle of Man',
//     dial_code: '+44',
//     emoji: '🇮🇲',
//     id: 'IM',
//   },
//   {
//     name: 'Israel',
//     dial_code: '+972',
//     emoji: '🇮🇱',
//     id: 'IL',
//   },
//   {
//     name: 'Italy',
//     dial_code: '+39',
//     emoji: '🇮🇹',
//     id: 'IT',
//   },
//   {
//     name: 'Jamaica',
//     dial_code: '+1876',
//     emoji: '🇯🇲',
//     id: 'JM',
//   },
//   {
//     name: 'Japan',
//     dial_code: '+81',
//     emoji: '🇯🇵',
//     id: 'JP',
//   },
//   {
//     name: 'Jersey',
//     dial_code: '+44',
//     emoji: '🇯🇪',
//     id: 'JE',
//   },
//   {
//     name: 'Jordan',
//     dial_code: '+962',
//     emoji: '🇯🇴',
//     id: 'JO',
//   },
//   {
//     name: 'Kazakhstan',
//     dial_code: '+77',
//     emoji: '🇰🇿',
//     id: 'KZ',
//   },
//   {
//     name: 'Kenya',
//     dial_code: '+254',
//     emoji: '🇰🇪',
//     id: 'KE',
//   },
//   {
//     name: 'Kiribati',
//     dial_code: '+686',
//     emoji: '🇰🇮',
//     id: 'KI',
//   },
//   {
//     name: "Korea, Democratic People's Republic of Korea",
//     dial_code: '+850',
//     emoji: '🇰🇵',
//     id: 'KP',
//   },
//   {
//     name: 'Korea, Republic of South Korea',
//     dial_code: '+82',
//     emoji: '🇰🇷',
//     id: 'KR',
//   },
//   {
//     name: 'Kuwait',
//     dial_code: '+965',
//     emoji: '🇰🇼',
//     id: 'KW',
//   },
//   {
//     name: 'Kyrgyzstan',
//     dial_code: '+996',
//     emoji: '🇰🇬',
//     id: 'KG',
//   },
//   {
//     name: 'Laos',
//     dial_code: '+856',
//     emoji: '🇱🇦',
//     id: 'LA',
//   },
//   {
//     name: 'Latvia',
//     dial_code: '+371',
//     emoji: '🇱🇻',
//     id: 'LV',
//   },
//   {
//     name: 'Lebanon',
//     dial_code: '+961',
//     emoji: '🇱🇧',
//     id: 'LB',
//   },
//   {
//     name: 'Lesotho',
//     dial_code: '+266',
//     emoji: '🇱🇸',
//     id: 'LS',
//   },
//   {
//     name: 'Liberia',
//     dial_code: '+231',
//     emoji: '🇱🇷',
//     id: 'LR',
//   },
//   {
//     name: 'Libyan Arab Jamahiriya',
//     dial_code: '+218',
//     emoji: '🇱🇾',
//     id: 'LY',
//   },
//   {
//     name: 'Liechtenstein',
//     dial_code: '+423',
//     emoji: '🇱🇮',
//     id: 'LI',
//   },
//   {
//     name: 'Lithuania',
//     dial_code: '+370',
//     emoji: '🇱🇹',
//     id: 'LT',
//   },
//   {
//     name: 'Luxembourg',
//     dial_code: '+352',
//     emoji: '🇱🇺',
//     id: 'LU',
//   },
//   {
//     name: 'Macao',
//     dial_code: '+853',
//     emoji: '🇲🇴',
//     id: 'MO',
//   },
//   {
//     name: 'Macedonia',
//     dial_code: '+389',
//     emoji: '🇲🇰',
//     id: 'MK',
//   },
//   {
//     name: 'Madagascar',
//     dial_code: '+261',
//     emoji: '🇲🇬',
//     id: 'MG',
//   },
//   {
//     name: 'Malawi',
//     dial_code: '+265',
//     emoji: '🇲🇼',
//     id: 'MW',
//   },
//   {
//     name: 'Malaysia',
//     dial_code: '+60',
//     emoji: '🇲🇾',
//     id: 'MY',
//   },
//   {
//     name: 'Maldives',
//     dial_code: '+960',
//     emoji: '🇲🇻',
//     id: 'MV',
//   },
//   {
//     name: 'Mali',
//     dial_code: '+223',
//     emoji: '🇲🇱',
//     id: 'ML',
//   },
//   {
//     name: 'Malta',
//     dial_code: '+356',
//     emoji: '🇲🇹',
//     id: 'MT',
//   },
//   {
//     name: 'Marshall Islands',
//     dial_code: '+692',
//     emoji: '🇲🇭',
//     id: 'MH',
//   },
//   {
//     name: 'Martinique',
//     dial_code: '+596',
//     emoji: '🇲🇶',
//     id: 'MQ',
//   },
//   {
//     name: 'Mauritania',
//     dial_code: '+222',
//     emoji: '🇲🇷',
//     id: 'MR',
//   },
//   {
//     name: 'Mauritius',
//     dial_code: '+230',
//     emoji: '🇲🇺',
//     id: 'MU',
//   },
//   {
//     name: 'Mayotte',
//     dial_code: '+262',
//     emoji: '🇾🇹',
//     id: 'YT',
//   },
//   {
//     name: 'Mexico',
//     dial_code: '+52',
//     emoji: '🇲🇽',
//     id: 'MX',
//   },
//   {
//     name: 'Micronesia, Federated States of Micronesia',
//     dial_code: '+691',
//     emoji: '🇫🇲',
//     id: 'FM',
//   },
//   {
//     name: 'Moldova',
//     dial_code: '+373',
//     emoji: '🇲🇩',
//     id: 'MD',
//   },
//   {
//     name: 'Monaco',
//     dial_code: '+377',
//     emoji: '🇲🇨',
//     id: 'MC',
//   },
//   {
//     name: 'Mongolia',
//     dial_code: '+976',
//     emoji: '🇲🇳',
//     id: 'MN',
//   },
//   {
//     name: 'Montenegro',
//     dial_code: '+382',
//     emoji: '🇲🇪',
//     id: 'ME',
//   },
//   {
//     name: 'Montserrat',
//     dial_code: '+1664',
//     emoji: '🇲🇸',
//     id: 'MS',
//   },
//   {
//     name: 'Morocco',
//     dial_code: '+212',
//     emoji: '🇲🇦',
//     id: 'MA',
//   },
//   {
//     name: 'Mozambique',
//     dial_code: '+258',
//     emoji: '🇲🇿',
//     id: 'MZ',
//   },
//   {
//     name: 'Myanmar',
//     dial_code: '+95',
//     emoji: '🇲🇲',
//     id: 'MM',
//   },
//   {
//     name: 'Namibia',
//     emoji: '🇳🇦',
//     dial_code: '+264',
//     id: 'NA',
//   },
//   {
//     name: 'Nauru',
//     dial_code: '+674',
//     emoji: '🇳🇷',
//     id: 'NR',
//   },
//   {
//     name: 'Nepal',
//     dial_code: '+977',
//     emoji: '🇳🇵',
//     id: 'NP',
//   },
//   {
//     name: 'Netherlands',
//     dial_code: '+31',
//     emoji: '🇳🇱',
//     id: 'NL',
//   },
//   {
//     name: 'Netherlands Antilles',
//     dial_code: '+599',
//     emoji: '🇧🇶',
//     id: 'AN',
//   },
//   {
//     name: 'New Caledonia',
//     dial_code: '+687',
//     emoji: '🇳🇨',
//     id: 'NC',
//   },
//   {
//     name: 'New Zealand',
//     dial_code: '+64',
//     emoji: '🇳🇿',
//     id: 'NZ',
//   },
//   {
//     name: 'Nicaragua',
//     dial_code: '+505',
//     emoji: '🇳🇮',
//     id: 'NI',
//   },
//   {
//     name: 'Niger',
//     dial_code: '+227',
//     emoji: '🇳🇪',
//     id: 'NE',
//   },
//   {
//     name: 'Nigeria',
//     dial_code: '+234',
//     emoji: '🇳🇬',
//     id: 'NG',
//   },
//   {
//     name: 'Niue',
//     dial_code: '+683',
//     emoji: '🇳🇺',
//     id: 'NU',
//   },
//   {
//     name: 'Norfolk Island',
//     dial_code: '+672',
//     emoji: '🇳🇫',
//     id: 'NF',
//   },
//   {
//     name: 'Northern Mariana Islands',
//     dial_code: '+1670',
//     emoji: '🇲🇵',
//     id: 'MP',
//   },
//   {
//     name: 'Norway',
//     dial_code: '+47',
//     emoji: '🇳🇴',
//     id: 'NO',
//   },
//   {
//     name: 'Oman',
//     dial_code: '+968',
//     emoji: '🇴🇲',
//     id: 'OM',
//   },
//   {
//     name: 'Pakistan',
//     dial_code: '+92',
//     emoji: '🇵🇰',
//     id: 'PK',
//   },
//   {
//     name: 'Palau',
//     dial_code: '+680',
//     emoji: '🇵🇼',
//     id: 'PW',
//   },
//   {
//     name: 'Palestinian Territory, Occupied',
//     dial_code: '+970',
//     emoji: '🇵🇸',
//     id: 'PS',
//   },
//   {
//     name: 'Panama',
//     dial_code: '+507',
//     emoji: '🇵🇦',
//     id: 'PA',
//   },
//   {
//     name: 'Papua New Guinea',
//     dial_code: '+675',
//     emoji: '🇵🇬',
//     id: 'PG',
//   },
//   {
//     name: 'Paraguay',
//     dial_code: '+595',
//     emoji: '🇵🇾',
//     id: 'PY',
//   },
//   {
//     name: 'Peru',
//     dial_code: '+51',
//     emoji: '🇵🇪',
//     id: 'PE',
//   },
//   {
//     name: 'Philippines',
//     dial_code: '+63',
//     emoji: '🇵🇭',
//     id: 'PH',
//   },
//   {
//     name: 'Pitcairn',
//     dial_code: '+872',
//     emoji: '🇵🇳',
//     id: 'PN',
//   },
//   {
//     name: 'Poland',
//     dial_code: '+48',
//     emoji: '🇵🇱',
//     id: 'PL',
//   },
//   {
//     name: 'Portugal',
//     dial_code: '+351',
//     emoji: '🇵🇹',
//     id: 'PT',
//   },
//   {
//     name: 'Puerto Rico',
//     dial_code: '+1939',
//     emoji: '🇵🇷',
//     id: 'PR',
//   },
//   {
//     name: 'Qatar',
//     dial_code: '+974',
//     emoji: '🇶🇦',
//     id: 'QA',
//   },
//   {
//     name: 'Romania',
//     dial_code: '+40',
//     emoji: '🇷🇴',
//     id: 'RO',
//   },
//   {
//     name: 'Russia',
//     dial_code: '+7',
//     emoji: '🇷🇺',
//     id: 'RU',
//   },
//   {
//     name: 'Rwanda',
//     dial_code: '+250',
//     emoji: '🇷🇼',
//     id: 'RW',
//   },
//   {
//     name: 'Reunion',
//     dial_code: '+262',
//     emoji: '🇷🇪',
//     id: 'RE',
//   },
//   {
//     name: 'Saint Barthelemy',
//     dial_code: '+590',
//     emoji: '🇧🇱',
//     id: 'BL',
//   },
//   {
//     name: 'Saint Helena, Ascension and Tristan Da Cunha',
//     dial_code: '+290',
//     emoji: '🇸🇭',
//     id: 'SH',
//   },
//   {
//     name: 'Saint Kitts and Nevis',
//     dial_code: '+1869',
//     emoji: '🇰🇳',
//     id: 'KN',
//   },
//   {
//     name: 'Saint Lucia',
//     dial_code: '+1758',
//     emoji: '🇱🇨',
//     id: 'LC',
//   },
//   {
//     name: 'Saint Martin',
//     dial_code: '+590',
//     emoji: '🇲🇫',
//     id: 'MF',
//   },
//   {
//     name: 'Saint Pierre and Miquelon',
//     dial_code: '+508',
//     emoji: '🇵🇲',
//     id: 'PM',
//   },
//   {
//     name: 'Saint Vincent and the Grenadines',
//     dial_code: '+1784',
//     emoji: '🇻🇨',
//     id: 'VC',
//   },
//   {
//     name: 'Samoa',
//     dial_code: '+685',
//     emoji: '🇼🇸',
//     id: 'WS',
//   },
//   {
//     name: 'San Marino',
//     dial_code: '+378',
//     emoji: '🇸🇲',
//     id: 'SM',
//   },
//   {
//     name: 'Sao Tome and Principe',
//     dial_code: '+239',
//     emoji: '🇸🇹',
//     id: 'ST',
//   },
//   {
//     name: 'Saudi Arabia',
//     dial_code: '+966',
//     emoji: '🇸🇦',
//     id: 'SA',
//   },
//   {
//     name: 'Senegal',
//     dial_code: '+221',
//     emoji: '🇸🇳',
//     id: 'SN',
//   },
//   {
//     name: 'Serbia',
//     dial_code: '+381',
//     emoji: '🇷🇸',
//     id: 'RS',
//   },
//   {
//     name: 'Seychelles',
//     dial_code: '+248',
//     emoji: '🇸🇨',
//     id: 'SC',
//   },
//   {
//     name: 'Sierra Leone',
//     dial_code: '+232',
//     emoji: '🇸🇱',
//     id: 'SL',
//   },
//   {
//     name: 'Singapore',
//     dial_code: '+65',
//     emoji: '🇸🇬',
//     id: 'SG',
//   },
//   {
//     name: 'Slovakia',
//     dial_code: '+421',
//     emoji: '🇸🇰',
//     id: 'SK',
//   },
//   {
//     name: 'Slovenia',
//     dial_code: '+386',
//     emoji: '🇸🇮',
//     id: 'SI',
//   },
//   {
//     name: 'Solomon Islands',
//     dial_code: '+677',
//     emoji: '🇸🇧',
//     id: 'SB',
//   },
//   {
//     name: 'Somalia',
//     dial_code: '+252',
//     emoji: '🇸🇴',
//     id: 'SO',
//   },
//   {
//     name: 'South Africa',
//     dial_code: '+27',
//     emoji: '🇿🇦',
//     id: 'ZA',
//   },
//   {
//     name: 'South Sudan',
//     dial_code: '+211',
//     emoji: '🇸🇸',
//     id: 'SS',
//   },
//   {
//     name: 'South Georgia and the South Sandwich Islands',
//     dial_code: '+500',
//     emoji: '🇬🇸',
//     id: 'GS',
//   },
//   {
//     name: 'Spain',
//     dial_code: '+34',
//     emoji: '🇪🇸',
//     id: 'ES',
//   },
//   {
//     name: 'Sri Lanka',
//     dial_code: '+94',
//     emoji: '🇱🇰',
//     id: 'LK',
//   },
//   {
//     name: 'Sudan',
//     dial_code: '+249',
//     emoji: '🇸🇩',
//     id: 'SD',
//   },
//   {
//     name: 'Suriname',
//     dial_code: '+597',
//     emoji: '🇸🇷',
//     id: 'SR',
//   },
//   {
//     name: 'Svalbard and Jan Mayen',
//     dial_code: '+47',
//     emoji: '🇸🇯',
//     id: 'SJ',
//   },
//   {
//     name: 'Swaziland',
//     dial_code: '+268',
//     emoji: '🇸🇿',
//     id: 'SZ',
//   },
//   {
//     name: 'Sweden',
//     dial_code: '+46',
//     emoji: '🇸🇪',
//     id: 'SE',
//   },
//   {
//     name: 'Switzerland',
//     dial_code: '+41',
//     emoji: '🇨🇭',
//     id: 'CH',
//   },
//   {
//     name: 'Syrian Arab Republic',
//     dial_code: '+963',
//     emoji: '🇸🇾',
//     id: 'SY',
//   },
//   {
//     name: 'Taiwan',
//     dial_code: '+886',
//     emoji: '🇹🇼',
//     id: 'TW',
//   },
//   {
//     name: 'Tajikistan',
//     dial_code: '+992',
//     emoji: '🇹🇯',
//     id: 'TJ',
//   },
//   {
//     name: 'Tanzania, United Republic of Tanzania',
//     dial_code: '+255',
//     emoji: '🇹🇿',
//     id: 'TZ',
//   },
//   {
//     name: 'Thailand',
//     dial_code: '+66',
//     emoji: '🇹🇭',
//     id: 'TH',
//   },
//   {
//     name: 'Timor-Leste',
//     dial_code: '+670',
//     emoji: '🇹🇱',
//     id: 'TL',
//   },
//   {
//     name: 'Togo',
//     dial_code: '+228',
//     emoji: '🇹🇬',
//     id: 'TG',
//   },
//   {
//     name: 'Tokelau',
//     dial_code: '+690',
//     emoji: '🇹🇰',
//     id: 'TK',
//   },
//   {
//     name: 'Tonga',
//     dial_code: '+676',
//     emoji: '🇹🇴',
//     id: 'TO',
//   },
//   {
//     name: 'Trinidad and Tobago',
//     dial_code: '+1868',
//     emoji: '🇹🇹',
//     id: 'TT',
//   },
//   {
//     name: 'Tunisia',
//     dial_code: '+216',
//     emoji: '🇹🇳',
//     id: 'TN',
//   },
//   {
//     name: 'Turkey',
//     dial_code: '+90',
//     emoji: '🇹🇷',
//     id: 'TR',
//   },
//   {
//     name: 'Turkmenistan',
//     dial_code: '+993',
//     emoji: '🇹🇲',
//     id: 'TM',
//   },
//   {
//     name: 'Turks and Caicos Islands',
//     dial_code: '+1649',
//     emoji: '🇹🇨',
//     id: 'TC',
//   },
//   {
//     name: 'Tuvalu',
//     dial_code: '+688',
//     emoji: '🇹🇻',
//     id: 'TV',
//   },
//   {
//     name: 'Uganda',
//     dial_code: '+256',
//     emoji: '🇺🇬',
//     id: 'UG',
//   },
//   {
//     name: 'Ukraine',
//     dial_code: '+380',
//     emoji: '🇺🇦',
//     id: 'UA',
//   },
//   {
//     name: 'United Arab Emirates',
//     dial_code: '+971',
//     emoji: '🇦🇪',
//     id: 'AE',
//   },
//   {
//     name: 'United Kingdom',
//     dial_code: '+44',
//     emoji: '🇬🇧',
//     id: 'GB',
//   },
//   {
//     name: 'United States',
//     dial_code: '+1',
//     emoji: '🇺🇸',
//     id: 'US',
//   },
//   {
//     name: 'Uruguay',
//     dial_code: '+598',
//     emoji: '🇺🇾',
//     id: 'UY',
//   },
//   {
//     name: 'Uzbekistan',
//     dial_code: '+998',
//     emoji: '🇺🇿',
//     id: 'UZ',
//   },
//   {
//     name: 'Vanuatu',
//     dial_code: '+678',
//     emoji: '🇻🇺',
//     id: 'VU',
//   },
//   {
//     name: 'Venezuela, Bolivarian Republic of Venezuela',
//     dial_code: '+58',
//     emoji: '🇻🇪',
//     id: 'VE',
//   },
//   {
//     name: 'Vietnam',
//     dial_code: '+84',
//     emoji: '🇻🇳',
//     id: 'VN',
//   },
//   {
//     name: 'Virgin Islands, British',
//     dial_code: '+1284',
//     emoji: '🇻🇬',
//     id: 'VG',
//   },
//   {
//     name: 'Virgin Islands, U.S.',
//     dial_code: '+1340',
//     emoji: '🇻🇮',
//     id: 'VI',
//   },
//   {
//     name: 'Wallis and Futuna',
//     dial_code: '+681',
//     emoji: '🇼🇫',
//     id: 'WF',
//   },
//   {
//     name: 'Yemen',
//     dial_code: '+967',
//     emoji: '🇾🇪',
//     id: 'YE',
//   },
//   {
//     name: 'Zambia',
//     dial_code: '+260',
//     emoji: '🇿🇲',
//     id: 'ZM',
//   },
//   {
//     name: 'Zimbabwe',
//     dial_code: '+263',
//     emoji: '🇿🇼',
//     id: 'ZW',
//   },
// ];

const countries = [
  {
    name: 'Egypt',
    dial_code: '+20',
    emoji: '🇪🇬',
    id: 'EG',
    regex:/^\+?(20)?[0-9]{10}$/
  },
  {
    name: 'Saudi Arabia',
    dial_code: '+966',
    emoji: '🇸🇦',
    id: 'SA',
    regex:/^\+?(966)?[0-9]{9}$/
  },
  {
    name: 'United Arab Emirates',
    dial_code: '+971',
    emoji: '🇦🇪',
    id: 'AE',
    regex:/^\+?(971)?[0-9]{9}$/
  },
  {
    name: 'Turkey',
    dial_code: '+90',
    emoji: '🇹🇷',
    id: 'TR',
    regex:/^\+?(90)?[0-9]{10}$/
  },
  {
    name: 'Yemen',
    dial_code: '+967',
    emoji: '🇾🇪',
    id: 'YE',
    regex:/^\+?(967)?[0-9]{7,9}$/
  },  {
    name: 'United States',
    dial_code: '+1',
    emoji: '🇺🇸',
    id: 'US',
    regex:/^\+?(1)?[-.\s]?\(?[0-9]{3}\)?[-.\s]?[0-9]{3}[-.\s]?[0-9]{4}$/
    },
]

export default countries;
