<template>
  <div class="phone-input--wrap relative">
    <div class="phone-input mb-[10px]" :class="{ 'has-error': error }">
      <div
        class="cursor-pointer py-2 pr-1.5 pl-2 rounded-tl-md rounded-bl-md flex items-center justify-center gap-1.5 bg-slate-25 dark:bg-slate-700 h-10 w-14"
        @click="toggleCountryDropdown"
      >
        <h5 v-if="activeCountry" class="mb-0">
          {{ activeCountry.emoji }}
        </h5>
        <fluent-icon v-else icon="globe" class="fluent-icon" size="16" />
        <fluent-icon icon="chevron-down" class="fluent-icon" size="12" />
      </div>
      <span
        v-if="activeDialCode"
        class="flex bg-white dark:bg-slate-900 font-medium text-slate-800 dark:text-slate-100 font-normal text-base leading-normal py-2 pl-2 pr-0"
      >
        {{ activeDialCode }}
      </span>
      <input
        :value="phoneNumber"
        type="tel"
        class="phone-input--field"
        :placeholder="placeholder"
        :readonly="readonly"
        :style="styles"
        @input="onChange"
        @blur="onBlur"
        required
      />
    </div>
    <div v-if="showDropdown" ref="dropdown" class="country-dropdown">
      <div class="dropdown-search--wrap">
        <input
          ref="searchbar"
          v-model="searchCountry"
          type="text"
          placeholder="Search"
          class="dropdown-search"
        />
      </div>
      <div
        v-for="(country, index) in filteredCountriesBySearch"
        ref="dropdownItem"
        :key="index"
        class="country-dropdown--item"
        :class="{
          active: country.id === activeCountryCode,
          focus: index === selectedIndex,
        }"
        @click="onSelectCountry(country)"
      >
        <span class="text-base mr-1">{{ country.emoji }}</span>

        <span
          class="max-w-[7.5rem] overflow-hidden text-ellipsis whitespace-nowrap"
        >
          {{ country.name }}
        </span>
        <span class="ml-1 text-slate-300 dark:text-slate-300 text-xs">{{
          country.dial_code
        }}</span>
      </div>
      <div v-if="filteredCountriesBySearch.length === 0">
        <span
          class="flex items-center justify-center text-sm text-slate-500 dark:text-slate-300 mt-4"
        >
          No results found
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import countries from 'shared/constants/countries.js';
import eventListenerMixins from 'shared/mixins/eventListenerMixins';
import {
  hasPressedArrowUpKey,
  hasPressedArrowDownKey,
  isEnter,
} from 'shared/helpers/KeyboardHelpers';

export default {
  mixins: [eventListenerMixins],
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    styles: {
      type: Object,
      default: () => ({}),
    },
    error: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      countries: [
        {
          name: 'Select Country',
          dial_code: '',
          emoji: '',
          id: '',
        },
        ...countries,
      ],
      selectedIndex: -1,
      showDropdown: false,
      searchCountry: '',
      activeCountryCode: '',
      activeDialCode: '',
      phoneNumber: this.value,
    };
  },
  computed: {
    filteredCountriesBySearch() {
      return this.countries.filter(country => {
        const { name, dial_code, id } = country;
        const search = this.searchCountry.toLowerCase();
        return (
          name.toLowerCase().includes(search) ||
          dial_code.toLowerCase().includes(search) ||
          id.toLowerCase().includes(search)
        );
      });
    },
    activeCountry() {
      if (this.activeCountryCode) {
        return this.countries.find(
          country => country.id === this.activeCountryCode
        );
      }
      return '';
    },
  },
  watch: {
    value(newValue) {
      this.phoneNumber = newValue;
    },
  },
  mounted() {
    window.addEventListener('mouseup', this.onOutsideClick);
    this.setActiveCountry();
  },
  beforeDestroy() {
    window.removeEventListener('mouseup', this.onOutsideClick);
  },
  methods: {
    onOutsideClick(e) {
      if (
        this.showDropdown &&
        e.target !== this.$refs.dropdown &&
        !this.$refs.dropdown.contains(e.target)
      ) {
        this.closeDropdown();
      }
    },
    onChange(e) {
      this.phoneNumber = e.target.value;
      this.$emit('input', e.target.value, this.activeDialCode);
    },
    onBlur(e) {
      this.$emit('blur', e.target.value);
    },
    getDropdownItems() {
      return Array.from(
        this.$refs.dropdown.querySelectorAll(
          'div.country-dropdown div.country-dropdown--item'
        )
      );
    },
    getFocusedItem() {
      return Array.from(
        this.$refs.dropdown.querySelectorAll('div.country-dropdown div.focus')
      );
    },
    getFocusedItemIndex() {
      return Array.from(this.getDropdownItems()).indexOf(this.getFocusedItem()[0]);
    },
    onKeyDownHandler(e) {
      const { showDropdown, filteredCountriesBySearch, onSelectCountry } = this;
      const { selectedIndex } = this;

      if (showDropdown) {
        if (hasPressedArrowDownKey(e)) {
          e.preventDefault();
          this.selectedIndex = Math.min(
            selectedIndex + 1,
            filteredCountriesBySearch.length - 1
          );
          this.$refs.dropdown.scrollTop = this.getFocusedItemIndex() * 28;
        } else if (hasPressedArrowUpKey(e)) {
          e.preventDefault();
          this.selectedIndex = Math.max(selectedIndex - 1, 0);
          this.$refs.dropdown.scrollTop = this.getFocusedItemIndex() * 28 - 56;
        } else if (isEnter(e)) {
          e.preventDefault();
          onSelectCountry(filteredCountriesBySearch[selectedIndex]);
        }
      }
    },
    onSelectCountry(country) {
      this.activeCountryCode = country.id;
      this.searchCountry = '';
      this.activeDialCode = country.dial_code;
      this.$emit('setCode', country.dial_code);
      this.$emit('setRegex', country.regex); //regex emit
      this.closeDropdown();
    },
    setActiveCountry() {
      const country = this.countries.find(
        country => country.dial_code === this.activeDialCode
      );
      if (country) {
        this.activeCountryCode = country.id;
        this.activeDialCode = country.dial_code;
        this.$emit('setCode', country.dial_code);
        this.$emit('setRegex', country.regex);
      }
    },
    toggleCountryDropdown() {
      this.showDropdown = !this.showDropdown;
      if (this.showDropdown) {
        this.$nextTick(() => {
          this.$refs.searchbar.focus();
        });
      }
    },
    closeDropdown() {
      this.showDropdown = false;
    },
  },
};
</script>

<style scoped>
.phone-input--wrap {
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  position: relative;
}

.phone-input {
  display: flex;
  align-items: center;
  border: 1px solid #d1d5db;
  border-radius: 0.375rem;
  overflow: hidden;
}

.phone-input--field {
  width: 100%;
  height: 100%;
  padding: 0.625rem;
  font-size: 1rem;
  border: none;
  outline: none;
  background: #fff;
}

.country-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  background: #fff;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
  border: 1px solid #e5e7eb;
  border-radius: 0.375rem;
  z-index: 10;
}

.dropdown-search--wrap {
  padding: 0.625rem;
  border-bottom: 1px solid #e5e7eb;
}

.dropdown-search {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #d1d5db;
  border-radius: 0.375rem;
  outline: none;
}

.country-dropdown--item {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.625rem;
  cursor: pointer;
}

.country-dropdown--item.active,
.country-dropdown--item.focus {
  background: #f3f4f6;
}

.country-dropdown--item:hover {
  background: #f3f4f6;
}

.country-dropdown--item .fluent-icon {
  margin-left: auto;
}
</style>
